#titleparent{
    color: #00fc0d;
    display: block;
    padding-bottom: 52vh;
    /*animation: 3s pulse;*/
}

#tagline{
    color: #03b1fc;
    font-size: 2.5vh;
    font-family: 'Source Code Pro', monospace;
    font-weight: 400;
    margin: 0;
    padding: 0;
    height: 1.8vh;
    letter-spacing: 5px;
    /*text-align: center;*/
}

#title{
    font-size: 1.8vh;
    margin: 0;
    padding: 0;
}

@keyframes pulse {
    0% {
      transform: scale3d(1, 1, 1);
      color:#00fc0d;
    }
    50%{
        transform: scale3d(1.3, 1.3, 1.3);
        color:#09b4ed;
    }
    100% {
        transform: scale3d(1, 1, 1);
      color:#00fc0d;
    }
}