html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#root {
}

#mainCanvas{
    position: absolute; width: 100%; height: 100%; overflow: hidden;
    z-index: 10;
}


#overlay{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    z-index: 10;
    display: block;
    height: 50vh;
}

#titleOverlay{
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    background-color: rgba(255, 0, 0, 0);
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;
}