#about-title{
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  color: rgb(192, 181, 181); /*rgb(51, 48, 48)*/
  font-size: 4vw;
  line-height: 5vw;
  color: #00fc0d;
  font-family: 'Roboto Mono', monospace;
}

.blinking{
  font-size: 6vw;
  animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
  0%{     color: #00fc0d;    }
  49%{    color: #00fc0d; }
  60%{    color: white; }
  99%{    color:white;  }
  100%{   color: #00fc0d;    }
}


#about-overlay{
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  display: block;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: black;

}

#aboutContainer{
  opacity: 1;
}

.aboutContent{
  margin: 1rem 0rem;
  padding: 1rem 0rem;
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  font-style: normal;
  font-size: 2vw; /*2.6vw*/
  color: white;
  width: 70vw;
  opacity: 0;
}