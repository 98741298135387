html,
body,
#root {
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  background-color: black;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
}

#primary-div{
  position: absolute;
  background-color: black;
}


#exmode{
  position: absolute;
  background-color: black;
}


.spinner {
  background-color: black;
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
/*   position: absolute;
  left: 50%;
  top: 25%; */
  position: absolute;
  margin: auto;
  top: 45%;
  left: 25%;
  width: 50%;
  margin: 0 auto;
}

#spinOver{
  width: 100%;
  height: 100%;
}
#loadText{
  background-color: black;
  margin: 0;
  color: rgb(51, 255, 0);
  font-family: 'Source Code Pro', monospace;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
}
.loadText{
  background-color: black;
  margin: 0;
  color: rgb(51, 255, 0);
  font-family: 'Source Code Pro', monospace;
  font-weight: 700;
  font-size: 1.3rem;
  text-align: center;
}

.spinner > div {
  background-color: rgb(51, 255, 0);
  height: 40px;
  width: 6px;
  display: inline-block;
  margin: 0rem 0.25rem;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

#aud{
  position:absolute;
  bottom:1%;
  right:2%;
  z-index: 200;
  display: flex;
  flex-direction: column-reverse;
  font-family: 'Source Code Pro', monospace;
}

#music-cred{
  margin-left: 3%;
  z-index: 200;
  color: rgb(0, 255, 21);
  font-family: 'Source Code Pro', monospace;
}