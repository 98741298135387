
.blinking{
font-size: 6vw;
animation:blinkingText 1.2s infinite;
}
@keyframes blinkingText{
0%{     color: #00fc0d;    }
49%{    color: #00fc0d; }
60%{    color: white; }
99%{    color:white;  }
100%{   color: #00fc0d;    }
}

#experience-overlay::-webkit-scrollbar {
  display: none;
}

#experience-overlay{
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100% auto;
  z-index: 10;
  display: block;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background-color: black;
}


#experienceTitle{
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
  color: rgb(192, 181, 181); /*rgb(51, 48, 48)*/
  font-size: 4vw;
  line-height: 5vw;
  color: #00fc0d;
  font-family: 'Roboto Mono', monospace;
  padding-left: 3vw;
}


#experience-container{
  height: 100vh;
  padding-top: 1rem;
  background-color: #00000000;
  z-index: 1;
  z-index: 10;
  min-height: 100vh;
  height: 100% auto;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  height:auto;
}

.experienceCardWrapper{
  background-color: #EDF2F8; /*efe6ea, F9F7F3, 2b303a*/
  width: 80%;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  color: white;
  border-radius: 10px;
  font-family: 'Merriweather', serif;
  font-family: 'Montserrat', sans-serif;
  -webkit-box-shadow: 49px 29px 66px -24px rgba(0,0,0,0.71);
  -moz-box-shadow: 49px 29px 66px -24px rgba(0,0,0,0.71);
  box-shadow: 49px 29px 66px -24px rgba(0,0,0,0.71);
  
}


.experienceTitle{
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin: 2rem 2rem 1rem 2rem;
  text-align: center;
  align-items: center;
  
}

.experiencePosition{
  font-weight: 500;
  font-size: 1.2rem;
  color: #2b303a;
  text-align: center;
  margin-right: 1rem;
}

.experienceEmployer{
  font-size: 1.2rem;
  color: #2b303a;
  text-align: center;
  margin-left: 1rem;
}

.experienceDescription{
  font-size: 1rem;
  border-radius: 10px;
  margin: 0.5rem 2rem;
  color: #2b303a;
}

